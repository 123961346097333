import React, { FunctionComponent } from 'react';

import { graphql } from 'gatsby';

import ArticleList from '../components/ArticleList';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type TagsProps = {
  location: Location;
  pageContext: {
    tag: string;
  };
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: {
          excerpt: string;
          timeToRead: number;
          frontmatter: {
            title: string;
            date: string;
            timeEmoji: string;
            emojiRatio: number;
            ogimage: { 
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
              }
            }
          };
          fields: { slug: string };
        };
      }>;
    };
  };
};

const Tags: FunctionComponent<TagsProps> = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `#${tag} : ${totalCount} article${totalCount < 2 ? '' : 's'}`;

  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <Seo title={tag} />
      <div>
        <h2>{tagHeader}</h2>
        <ArticleList nodes={edges} />
      </div>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "DD MMM YYYY")
            title
            timeEmoji
            emojiRatio
            ogimage {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, JPG]
                )
              }
            }
          }
        }
      }
    }
  }
`;
